@import 'styles/colors';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box {
  display: block;
  text-decoration: none;
  font-size: 13px;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #70707014;
  box-shadow: 0 0 10px #00000016;
  margin-bottom: 10px;
}

* {
  box-sizing: border-box;
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.capitalized{
  text-transform: capitalize;
}

.page-header{
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  align-items: center;
  margin-bottom: 25px;
  i{
    margin-right: 15px;
  }
}

.utogi-table {
  border-spacing: 0;
  border: none;
  width: 100%;
  text-align: left;
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    color: $color2;
    font-weight: 600;
    font-size: 15px;
    button {
      width: 100%;
      text-align: left;
      outline: none;
      border: none;
      align-items: center;
      margin: 0;
      padding: 0.5rem;
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
      background: none;
      i {
        margin-left: 25px;
      }
    }
    span {
      display: inline-block;
      margin-left: 10px;
    }
    .unsorted {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      i {
        line-height: 1;
      }
    }
  }
  td {
    margin: 0;
    padding: 0.5rem;
    font-size: 14px;
    button{
      border: none;
      background: none;
      outline: none;
    }
    :last-child {
      border-right: 0;
    }
    .status {
      &-success {
        color: #73e5b0;
      }
      &-danger {
        color: #ff6265;
      }
    }
    a{
      text-decoration: none;
      color: initial;
    }
  }
}

button{
  cursor: pointer;
  outline: none;
}
